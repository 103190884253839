@media screen and (max-width: 900px) {
    .desktop {
        display: none;
        width: 0;
        height: 0;
    }
    .main-page {
        justify-content: center;
    }
}

@media screen and (max-height: 400px) {
    .desktop {
        display: none;
        width: 0;
        height: 0;
    }
    .main-page {
              justify-content: center;
    }
}

@media screen and (min-width: 900px) {
    .mobile-only-screenz {
        display: none;
        background-color: red;
    }
}


.page {
    min-width: 100%;
    min-height: 100%;
}

.page-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.main-page {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
    /*background-image: url("/bg-mobile.gif");*/
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top left;
}

@media screen and (min-width: 900px) {
    .main-page {

        background-image: url("/bg-desktop.gif");
    }
}

@media screen and (max-width: 899px) {
    .main-page {
        background-image: url("/bg-mobile.gif");
    }
}

.main-page .content-page {
    align-content: center !important;
    justify-content: center;
    min-height: 100%;
    margin-left: 15px;
    margin-right: 15px;
    min-width: 385px;
}

.main-page-item {
    min-height: 100%;
}

.video-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
}

.video-player {
    width: 100px;
    height: 100px;
    margin-top: 30px;
    margin-left: 20px;
}