.page-container {
    text-align: center;
    min-width: 100%;
    min-height: 100%;
    flex-direction: column;
}

.view-container {
    width: 100%;
    height: 100%;
    text-align: center;
}

.head-container {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.page-div {
    height: 100%;
}

.base-font {
    color: rgba(255, 255, 255, 0.8) !important;
}
